import React, { useState, useContext, useRef, useEffect, useReducer } from 'react';

import {
  XYPlot,
  XAxis,
  YAxis,
  HorizontalBarSeries, 
  DiscreteColorLegend, HorizontalGridLines, VerticalGridLines, LineSeries
} from 'react-vis';

import {
  isMobile
} from "react-device-detect";

import { OperationalContext } from './App';
import { DecisionContext } from './Decision';

function DeliberationCharts(props) {

  //Core hierarchy
  const { state, dispatch } = useContext(DecisionContext);

  //Local state
  const [selectedOption, setSelectedOption] = useState(undefined);

  //Build data
  const data = [];
  const maxValue = Math.max(...state.options.map(e => {
    if(e.impacts!= undefined){
      return e.impacts.reduce((accumulator, b) => {return accumulator + parseInt(b.value);}, 0)
    }
    return -100;
  }));

  state.options.forEach((element, index, array) => {
    let op = {};
    
    const minTitle = minimizeText(element.text);
    const hasImpacts = (element.impacts!=undefined);
    const hasInvalidImpact = (hasImpacts) ? element.impacts.reduce((accumulator, impact) => {
      return accumulator || ( (impact.isInvalidator != undefined) ? impact.isInvalidator : false)
    },false) : false;
    const impactsSum = (hasImpacts) ? element.impacts.reduce((accumulator, b) => {
      const result = accumulator + parseInt(b.value);
      return result;
    }, 0) : 0;
    const color = (hasInvalidImpact) ? "red" : getRandomColor();

    //Options properties
    op.invalid= hasInvalidImpact;
    op.y = minTitle;
    op.x = impactsSum;        
    op.title = (hasInvalidImpact) ? element.text +" (Invalidate)" : element.text;
    op.legend = (hasInvalidImpact) ? element.text +" (Invalidate)" : element.text;    
    op.color = (hasInvalidImpact) ? "red" : (maxValue===impactsSum) ? "#006600" : "#009900";
    op.opacity = (hasInvalidImpact) ? "10%" : "100";
    op.cost = (element.cost!=undefined) ? element.cost : 0;

    //Impacts properties
    if(hasImpacts){
      op.impacts = element.impacts.map(impact => {
        const iMinText = minimizeText(impact.text);
        return {
            y:iMinText, 
            x:impact.value,
            title:impact.text,
            legend: impact.text, 
            color: (impact.value>0) ? "#00cc00" : "red",
            opacity : (impact.value>0) ? "100%" : "60%"
        } 
      })
    }else{
      op.impacts = [];
    }
    data.push(op);
  });

  //Order
  data.sort((a,b) => (a.x > b.x) ? 1 : -1 );
  
  //Indexing
  data.forEach((val, index) => {    
    data[index].y = (data.length-index) + ". " + data[index].y;
    data[index].title = " " + (data.length-index) + ". " + data[index].title;
    const parentIndex = index;
    data[index].impacts = data[index].impacts.map( (impact, index) => {
      return {...impact, y:" "+(data[parentIndex].impacts.length-index)+". "+impact.y, title:" "+(data[parentIndex].impacts.length-index)+". "+impact.title};
    });
  });

  //http://uber.github.io/react-vis/documentation/api-reference/axes
  //LineSeries Draw a line 
  //We need to work with BarSeries Poner varias en un XYPOLT. Usar el Y0
  // https://uber.github.io/react-vis/documentation/series-reference/bar-series
  // https://github.com/uber/react-vis/blob/premodern/showcase/plot/clustered-stacked-bar-chart.js
  
  return (
    <div className="container">
      
      {/* Options */}
      <div className="row mt-4">
        <div className="col">
          <h3>
          Options in base of impacts{!!state.metadata.isCostActive && state.metadata.isCostActive &&<span className="col">and cost</span>}
          </h3>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col">          
          <XYPlot yType="ordinal" height={200} width={(isMobile) ? 300 : 500} margin={{ left: (isMobile) ? 120: 200, right: 10 }} >
            <HorizontalBarSeries data={data} colorType="literal"  />
            <YAxis
              orientation="left"
              position="start"       
              style={{line: { stroke: '#043521' }}}
            />
            <XAxis />            
          
          </XYPlot>         
          { (data.find(e => e.legend) != undefined) && <DiscreteColorLegend items={data.slice().reverse()} />}          
        </div>

        {!!state.metadata.isCostActive && state.metadata.isCostActive &&
        <div className="col">
        <XYPlot yType="ordinal" height={200} width={(isMobile) ? 300 : 500} margin={{ left: (isMobile) ? 120: 200, right: 10 }} >
          <HorizontalBarSeries colorType="literal"  data={data.map(option => ({y:option.y, x:option.cost}) )}  />
          
          <YAxis
            orientation="left"
            position="start"       
            style={{line: { stroke: '#043521' }}}
          />
          <XAxis tickFormat={v => ` ${v + state.metadata.magnitude} `}  tickLabelAngle={-45} />            
        
        </XYPlot> 
        </div> 
        }

      </div>

      <div className="row mt-5">
        <div className="col">
          <h4>Impacts</h4>
        </div>
      </div>

      {/* Impacts for option */}        
      <div className="row">
        {data.slice().reverse().map((option, index) => (     
          <div key={index} className="col mt-4">
            <h5 >{option.title}</h5>
            <XYPlot yType="ordinal" height={200} width={(isMobile) ? 300 : 500}  margin={{ left: (isMobile) ? 120: 200, right: 10 }} >
              <HorizontalBarSeries data={option.impacts} colorType="literal" />
              <YAxis
                orientation="left"
                position="start"    
                style={{line: { stroke: '#043521' }}}
              />
              <XAxis />
            </XYPlot>
            { (option.impacts.find(e => e.legend) != undefined) && <DiscreteColorLegend items={option.impacts.slice().reverse()} />}  
          </div>
        ))}
      </div>
    </div>
  );
}

export default DeliberationCharts;


function getRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

const maxSize = isMobile ? 8:18;
const minimizeText = (text) =>{    
  if(text.length < maxSize){
    return text;
  }else{
    let result = text.split(" ").reduce((accumulator, text) => {
      const result = accumulator + " " + text;
      if(result < maxSize){
        return result;
      }else{
        return accumulator;
      }                
    }, "");      
    if(result.length < 5){
      result = text.substring(0, maxSize)
    }
    return result + " ...";
  }
}
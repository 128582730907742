import React, { useState, useContext, useRef, useEffect, useReducer } from 'react';

import {
  isMobile
} from "react-device-detect";

import { OperationalContext } from './App';
import { DecisionContext } from './Decision';
import Tree from 'react-tree-graph';
import 'react-tree-graph/dist/style.css'

function DeliberationTree(props) {

  //Core hierarchy
  const { state, dispatch } = useContext(DecisionContext);

  //Local state
  const [selectedOption, setSelectedOption] = useState(undefined);

  const maxValue = Math.max(...state.options.map(e => {
    if(e.impacts!= undefined){
      return e.impacts.reduce((accumulator, b) => {return accumulator + parseInt(b.value);}, 0)
    }
    return -100;
  }));

  let decisionTree = {
    title: state.metadata.title,
    name : minimizeText(state.metadata.title),
    children : state.options.map((option, index) =>(
      {
        name: minimizeText(option.text),
        cost : option.cost,
        title: option.text,
        value: option.impacts.reduce((accumulator, b) => {return accumulator + parseInt(b.value);}, 0),
        invalid: option.impacts.reduce((accumulator, impact) => {return accumulator || ( (impact.isInvalidator != undefined) ? impact.isInvalidator : false)},false),        
        textProps : (maxValue === option.impacts.reduce((accumulator, b) => {return accumulator + parseInt(b.value);}, 0)) ? { className:"select" } : {},
        children:[
          {            
            name: 'pros', 
            pathProps: {
              className: 'pros',
            },
            textProps : { className:"pros" },
            children: option.impacts.filter(impact => (impact.value > 0)).map((impact, index) => {
              return (
                {title:impact.text, name: minimizeText(impact.text, 35),textProps : { className:"pros" },}
                );           
            })
          },
          {
            name: 'neutral',
            children: option.impacts.filter(impact => (impact.value === 0)).map((impact, index) => {
              return ({title:impact.text, name: minimizeText(impact.text, 35)});           
            })
          },
          {
            name: 'cons',         
            pathProps: {
              className: 'cons',
            },
            textProps : { className:"cons" },
            children: option.impacts.filter(impact => (impact.value < 0)).map((impact, index) => {
              return ({title:impact.text, name: minimizeText(impact.text, 35),textProps : { className:"cons" }} );           
            })
          }
        ]
      }
    ))
  };

  //Set invalid
  decisionTree.children.forEach((element, index) => {
    if(element.invalid){
      element.children.forEach((element2, index2) => {
        element.children[index2] = {...element2,pathProps: {className: 'invalid',}}
        element2.children.forEach((element3, index3) => {
          element2.children[index3] = {...element3,pathProps: {className: 'invalid',}}
        })
      })      
      decisionTree.children[index] = {...element, pathProps: {className: 'invalid',}}

    }
  });

  //Order
  decisionTree.children.sort((a,b) => (a.value < b.value) ? 1 : -1 );

  //Indexing
  decisionTree.children.forEach((val, index) => {
    const options = decisionTree.children;
    options[index].index = (index+1);  
    options[index].name = (index+1) + ". " + options[index].name;
  
    const proscons = options[index].children;
    let count = 1;
      proscons.forEach((proCon, index2) => {      
        const impacts = proscons[index2].children;
        proscons[index2].name = (index+1) + ". " + proscons[index2].name
        impacts.forEach((impact, index3) => {          
          impacts[index3].index = count;          
          impacts[index3].parentIndex = (index+1);
          impacts[index3].name = (index+1)+"."+(count) + ". " + impacts[index3].name;
          count++;
        })
      })
  });

  return (
    <div className="custom-container custom">
      <div className="row mt-4">
        <div className="col">
          <h3>Decision Tree</h3>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Tree
            data={decisionTree}
            height={500}
            width={800}
            margins={{bottom:10, left:20, right:250, top:10}}
            svgProps={{
              className: 'custom'
            }}            
            animated
            />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col">
          <h5>{state.metadata.title}</h5>
          <ul>
            {
              decisionTree.children.map((element, index) => (
                <React.Fragment key={index}>
                <li key={index}>
                    {element.index + ". " +element.title + " (Rate:"  + element.value + ")"}
                    {!!state.metadata.isCostActive && state.metadata.isCostActive && element.cost !=undefined &&
                    <span> (Cost: {element.cost + " " + state.metadata.magnitude})</span>
                    }
                </li>
                <ul className="mb-3">
                 {
                   element.children.map((element2) => (
                    element2.children.map((element3, index2) => (
                    <li key={index + " " + index2}>{(element3.parentIndex) +"." + element3.index + ". " + element3.title}</li>
                     ))
                   ))
                 }
                </ul>
                </React.Fragment>
              ))
            }
          </ul>
        </div>
      </div>
    </div>
  );
}

export default DeliberationTree;


const minimizeText = (text, size) =>{
  if(text === undefined) return "";
  const maxSize = (size!= undefined) ? size : 10;
  if(text.length < maxSize){
    return text;
  }else{
    let result = text.split(" ").reduce((accumulator, text) => {
      const result = accumulator + " " + text;
      if(result < maxSize){
        return result;
      }else{
        return accumulator;
      }                
    }, "");      
    if(result.length < 5){
      result = text.substring(0, maxSize)
    }
    return result + " ...";
  }
}

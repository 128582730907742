import React, {useState, useContext, useRef, useEffect, useReducer } from 'react';
import {Form, Button, Col} from 'react-bootstrap';
import { GrFormTrash, GrFormEdit, GrLinkNext } from 'react-icons/gr';
import { AiOutlineEnter, AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import { BiListMinus, BiListPlus} from 'react-icons/bi';
import { DecisionContext } from './Decision';
import {v4 as uuidv4} from 'uuid';
import {OperationalContext} from './App';
import Impact from './Impact.js';


function Option(props) {

    //Core hierarchy
    const {state, dispatch} = useContext(DecisionContext);

    //Op context
    const {operationalState, operationalController} = useContext(OperationalContext);

    //Option Attributes
    const [optionText, setOptionText] = useState(props.option === undefined ? "" : props.option.text);
    const [optionCost, setOptionCost] = useState(props.option === undefined ? 0 : props.option.cost);
    const [isEditingOption, setIsEditingOption] = useState(props.option === undefined);
    const [isListingImpacts, setIsListingImpacts] = useState(false);

    //Force Focus
    const inputRef = useRef();

    useEffect(() => {
        if(props.option == undefined && inputRef.current != undefined){
            inputRef.current.focus();
        }  
        if(props.option !=undefined && props.option.impacts == undefined){
            dispatch({type:'editOption', payload:{...props.option,impacts:[]}}); 
        }      
    },[]);

    const handleFormKeyPress = (event, item) => {
        if (event.key === "Enter") {
            event.preventDefault();
            handleClickEnter(item);            
        }
    }

    const handleClickEnter = (item) => {
        if(props.option === undefined){
            handleOptionEvents('add', item);           
        }else{
            handleOptionEvents('edit', item);           
        }
    }

    const handleOptionEvents = (event, item) => {
        if (item === "OptionText") {
            if(event === 'add'){
                dispatch({type:'addOption', payload:{text:optionText, cost:optionCost}}); 
                setOptionText(""); 
                setOptionCost("");
                setIsEditingOption(true);
            }else if(event === 'edit'){
                dispatch({type:'editOption', payload:{...props.option,text:optionText, cost:optionCost}}); 
                setIsEditingOption(false);
                operationalController({type:'isEditingOption', payload:false})
            }else if(event === 'remove'){
                dispatch({type:'removeOption', payload:props.option.id});                 
            }else if(event === 'up'){
                dispatch({type:'upOption', payload:props.option.id}); 
            }else if(event === 'down'){
                dispatch({type:'downOption', payload:props.option.id}); 
            }                                                  
        }
    }

    const sytles ={
        impactsWrapper: {
            backgroundColor:"#f0f0f5"
            ,paddingTop: "0px",
            paddingBottom: "0px",
        }
        ,removeButton:{
            backgroundColor:"#ffcccc"
        }
        ,invalidOption:{
            textDecoration:"line-through"
        },
        verticaltext :{
            //width: "1px",
            //wordWrap: "break-word",
            //whiteSpace: "pre-wrap", 
            transform: "rotate(-90deg)",            
            //transformOrigin: "left top 30"
         }
    }

    let hasInvalidateImpact = false;
    if(props.option!= undefined && props.option.impacts != undefined){
        hasInvalidateImpact = props.option.impacts.reduce((accumulator, impact) => {
            return accumulator || ( (impact.isInvalidator != undefined) ? impact.isInvalidator : false)
        },false)
    }

    let impactsColumsSize = isListingImpacts ? (props.option.impacts.length+2) : 1;
    impactsColumsSize = (!operationalState.isEditingImpact) ? impactsColumsSize : (impactsColumsSize-1);

    return (
        <>
        <tr className="" key={(props.option!=undefined && props.option.id!=undefined) ? props.option.id : 'addNew'} > 
            {isEditingOption  &&
                <>
                <td width="5%" colSpan="1" style={sytles.impactsWrapper}></td>
                <td  className="">
                <Form.Row>
                    <Col className="col-md-9 col">
                    <Form.Control
                        ref={inputRef} 
                        type="text"
                        value={optionText}
                        placeholder="Option"
                        onChange={event => setOptionText(event.target.value)}
                        onKeyPress={event => handleFormKeyPress(event, "OptionText")}
                    />                    
                    </Col>                
                    {!!state.metadata.isCostActive && state.metadata.isCostActive &&
                    <>
                        <Col className="col-md-2 col-3">
                        <Form.Control
                            type="number"
                            placeholder="Cost"
                            value={optionCost}
                            onChange={event => setOptionCost(event.target.value)}
                            onKeyPress={event => handleFormKeyPress(event, "OptionText")}
                        />
                        </Col>
                        <Col className="col-md-1 col-1">
                        {state.metadata.magnitude}
                        </Col>
                    </>
                    }
                </Form.Row>
                </td>
                <td  className="" >
                    <AiOutlineEnter onClick={event => handleClickEnter("OptionText")}/>
                </td>
                </>
            }
            {!isEditingOption &&
                <>
                <td width="5%" style={sytles.impactsWrapper} onClick={event => setIsListingImpacts(!isListingImpacts)}>
                    {!isListingImpacts && <BiListPlus onClick={event => setIsListingImpacts(!isListingImpacts)}/>}
                    {isListingImpacts && 
                        <>
                            <BiListMinus onClick={event => setIsListingImpacts(!isListingImpacts)}/> 
                        </>} 
                </td>
                <td width="93%">
                    <div className="row">
                        <div className = "col-md-10 col-10" style={hasInvalidateImpact ? sytles.invalidOption : null} >
                            <a onClick={event => setIsListingImpacts(!isListingImpacts)}> 
                            {props.index}. {props.option.text} 
                            {
                                !!state.metadata.isCostActive && state.metadata.isCostActive && props.option.cost!=undefined && 
                                <span> ({props.option.cost + " " + state.metadata.magnitude})</span>
                            } 
                            </a>                    
                            <GrFormEdit onClick={() => {setIsEditingOption(true);operationalController({type:'isEditingOption', payload:true});}} /> 
                        </div>
                        <div className = "col-md-2 col-2"> 
                            {props.index > 1 && <AiOutlineArrowUp className="float-right"  onClick={() => {handleOptionEvents("up","OptionText")}} /> }
                            {props.index < state.options.length && <AiOutlineArrowDown className="float-right"  onClick={() => {handleOptionEvents("down","OptionText")}} /> }
                        </div>
                    </div>                                    
                </td>                
                <td width="2%" style={sytles.removeButton}>
                    <GrFormTrash className="float-right" onClick={() => {handleOptionEvents("remove","OptionText")}}/>                                                             
                </td>
                </>
            }
        </tr>

        {isListingImpacts && !isEditingOption &&
            <tr onClick={event => setIsListingImpacts(!isListingImpacts)}>
                <td rowSpan={impactsColumsSize} style={sytles.impactsWrapper}>
                </td>
                <td colSpan={2} style={sytles.impactsWrapper} className="text-center" >                     
                  <small> --- Impacts --- </small>
                </td>
            </tr>
        }
        
        {isListingImpacts && !isEditingOption && props.option.impacts.map((impact, index) => (
            <React.Fragment key={impact.id}>
                <Impact key={impact.id} impact={impact}  index={(props.index)} subIndex={(index+1)} option={props.option}  ></Impact>            
            </React.Fragment>
        ))}

        {isListingImpacts && !isEditingOption && optionText != undefined && !operationalState.isEditingImpact  && 
            <Impact key={'addKey'} impact={undefined}  optionId={props.option.id} option={props.option}></Impact>
        }        
        </>
    );
}

export default Option;
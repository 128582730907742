export const replaceElement = (list, element) => {
    const elementIndex = list.findIndex(o => o.id === element.id);
    const newList = [
        ...list.slice(0,elementIndex),
        element,
        ...list.slice(elementIndex+1),
    ];
    return newList;
}

export const reorderList = (list, itemId, action) => {
    const elementIndex = list.findIndex(o => o.id === itemId);
    const result = (action === 'down') 
        ? [...list.slice(0,elementIndex), list[elementIndex+1], list[elementIndex],...list.slice(elementIndex+2)] 
        : [...list.slice(0,elementIndex-1), list[elementIndex], list[elementIndex-1],...list.slice(elementIndex+1)] 
    ;
    
    result.forEach((element, index) => {
        element.index = index;
    });
    return result;
}


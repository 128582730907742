import React, { useEffect, useReducer, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import Decision from './Decision';
import * as firebase from 'firebase';

export const OperationalContext = React.createContext();
export const operationalInitialState = {
  isEditingOption:false, isEditingImpact:false, isDeliberationScreen:false
};

function App(props) {

  const [operationalState, operationalController] = useReducer(operationalReducer, operationalInitialState);


  return (
    <OperationalContext.Provider value={{operationalState, operationalController}}>
    <div className="wkk container">
      <div className="row">
        <div className="col"></div>
      </div>
      <Decision decisionId = {(!!props && !!props.match && !!props.match.params) ? props.match.params.decisionId : undefined} />
    </div>
    </OperationalContext.Provider>
  );
}

function operationalReducer (operationalState, action){
  switch(action.type){
      case 'isEditingOption':
        return {...operationalState, isEditingOption:action.payload}
      case 'isEditingImpact':
        return {...operationalState, isEditingImpact:action.payload}
      case 'isDeliberationScreen':
        return {...operationalState, isDeliberationScreen:action.payload}       
      case 'isSavingDecision':
          return {...operationalState, isSavingDecision:action.payload}  
      case 'isConfiguringDecision':
        return {...operationalState, isConfiguringDecision:action.payload}      
      default: 
          return  {}
  }
}

export default App;

import React, {useState, useContext, useRef, useEffect, useReducer } from 'react';
import {Form, Button, Tabs, Tab} from 'react-bootstrap';
import { GrFormTrash, GrFormEdit, GrLinkNext } from 'react-icons/gr';
import { AiOutlineEnter, AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import { BiListMinus, BiListPlus} from 'react-icons/bi';
import { DecisionContext } from './Decision';
import {v4 as uuidv4} from 'uuid';
import {OperationalContext} from './App';
import Impact from './Impact.js';
import {reorderList} from './Utils';
import DeliberationCharts from './DeliberationCharts';
import DeliberationTree from './DeliberationTree';


function Deliberation(props) {

    //Core hierarchy
    const {state, dispatch} = useContext(DecisionContext);

    //Op context
    const {operationalState, operationalController} = useContext(OperationalContext);

    //Tabs
    const [tabKey, setTabKey] = useState('Chart');

    return (
        <div className="container mt-2">
            <div className="row">
                <div className="col-md-11">
                <h2>Deliveration of: {state.metadata.title} </h2>
                </div>
                <div className="col">
                    <Button
                        onClick={() => operationalController({ type: 'isDeliberationScreen', payload: false })}
                        aria-controls="example-collapse-text"
                        aria-expanded={false}
                        className="close" aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </Button>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col">
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={tabKey}
                        onSelect={(k) => setTabKey(k)}
                    >
                        <Tab key="Chart" eventKey="Chart" title="Chart">
                            <DeliberationCharts />
                        </Tab>
                        <Tab key="Tree" eventKey="Tree" title="Tree">
                            <DeliberationTree />
                        </Tab>                        
                    </Tabs>
               </div>                 
            </div>           
        </div>
    );
}


export default Deliberation;
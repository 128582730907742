import React, { useEffect, useReducer, useState } from 'react';
import  firebase from 'firebase';

//https://softauthor.com/firebase-querying-sorting-filtering-database-with-nodejs-client/
//https://firebase.google.com/docs/reference/node/firebase.database.Reference
//https://www.tothenew.com/blog/custom-ids-in-firebase/

export const FBGet = (decisionId) => {
    
    const decisionInitialState = {
        id:undefined, options:[], metadata : {title:'ummmm'}
    };

    const [data, setData] = useState(decisionInitialState);
    const dbRef = firebase.database().ref('/decisions/' + decisionId);

    useEffect(() => {
        if(decisionId!=undefined){
            getData()
        }
    },[]);

    const getData = async () => {
        dbRef.on('value', snapshot => {
            setData(snapshot.val());
        },errorObject => {
            console.log("The read failed: " + errorObject.code);
        });
    }
    return data;
}

export const FBUpdate = async (decisionId, decision) => {
    const dbRef = firebase.database().ref('/decisions/' + decisionId);
    const res = await dbRef.update(decision);
}

export const FBAdd = async (decision) => {
    const newDecision = firebase.database().ref('/').child("decisions");
    newDecision.child(decision.id).set(decision)
    //const result = (await newDecisions).set(decision);
}

import React, {useState, useContext, useRef, useImpact } from 'react';
import {Button, Form} from 'react-bootstrap';
import { GrFormTrash, GrFormEdit, GrCheckbox, GrCheckboxSelected } from 'react-icons/gr';
import { AiOutlineEnter, AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import { ImBaffled, ImNeutral, ImSad, ImSmile, ImWink, ImFrustrated, ImCrying, ImCool, ImHappy} from 'react-icons/im';
import {OperationalContext} from './App';
import { DecisionContext } from './Decision';
import {replaceElement,reorderList} from './Utils';

function Impact(props) {

    //Op context
    const {operationalState, operationalController} = useContext(OperationalContext);
    const {state, dispatch} = useContext(DecisionContext);

    //Impact Attributes
    const [impactText, setImpactText] = useState(props.impact === undefined ? "" : props.impact.text);
    const [impactValue, setImpactValue] = useState(props.impact === undefined || props.impact.value === undefined ? 0 : props.impact.value);
    const [isInvalidator, setIsInvalidator] = useState(props.impact === undefined || props.impact.isInvalidator === undefined ? false : props.impact.isInvalidator);
    
    const [isEditingImpact, setIsEditingImpact] = useState(props.impact === undefined);
    const impactRange = state.metadata.impactRange!=undefined ? state.metadata.impactRange : 5;

    const handleFormKeyPress = (event, item) => {
        if (event.key === "Enter") {
            event.preventDefault();
            handleClickEnter(item);            
        }
    }

    const handleClickEnter = (item) => {
        if(props.impact === undefined){
            handleImpactEvents('add', item);           
        }else{
            handleImpactEvents('edit', item);           
        }
    }

    const hotSetImpactValue = (inputValue) =>{
        const percentValue = getPercentValue(inputValue);
        setImpactValue(percentValue);        
        dispatch({type:'editImpact', payload:{impact:{...getImpact(), value:percentValue}, optionId:props.option.id}}) 
    }

    const hotSetIsInvalidator = (value) =>{
        setIsInvalidator(value);
        dispatch({type:'editImpact', payload:{impact:{...getImpact(), isInvalidator:value}, optionId:props.option.id}}) 
    }

    const getImpact = () => {
        return {...props.impact, text:impactText, value:impactValue, isInvalidator:isInvalidator};
    }

    const resetImpact = () => {
        setImpactText(""); 
        setImpactValue(0);
        setIsInvalidator(false);
    }

    const handleImpactEvents = (event, item) => {
        if (item === "ImpactText") {
            if(event === 'add'){
                dispatch({type:'addImpact', payload:{impact:getImpact(), optionId:props.option.id}})
                setIsEditingImpact(true); 
                resetImpact();                
            }else if(event === 'edit'){
                dispatch({type:'editImpact', payload:{impact:getImpact(), optionId:props.option.id}})
                setIsEditingImpact(false);
                operationalController({type:'isEditingImpact', payload:false})
            }else if(event === 'remove'){
                dispatch({type:'removeImpact', payload:{impactId:props.impact.id, optionId:props.option.id}})                
            }else if(event === 'up'){
                props.option.impacts = reorderList( props.option.impacts,props.impact.id,'up');
                dispatch({type:'editOption', payload:props.option}); 
            }else if(event === 'down'){
                props.option.impacts = reorderList( props.option.impacts,props.impact.id,'down'); 
                dispatch({type:'editOption', payload:props.option}); 
            }                                                  
        }
    }

    const getExplicitValue = (value) => {
        return (impactRange*value)/100;
    }

    const getPercentValue = (value) => {
        return (value*100)/impactRange;
    }

    const sytles ={
        removeButton:{
            backgroundColor:"#ffe6e6"
        }
    }

    const impactId = (props.impact!=undefined && props.impact.id!=undefined) ? props.impact.id : 'addNew';

    return (
        <>
        {isEditingImpact  &&
        <tr className="" key={impactId} > 
        <td className="">
            <div className="row">
                <div className = "col-md-6">
                    <Form.Control
                    type="text"
                    value={impactText}
                    placeholder="New impact .."
                    onChange={event => setImpactText(event.target.value)}
                    onKeyPress={event => handleFormKeyPress(event, "ImpactText")}
                    />   
                </div>
                <div className = "col-md-6 mt-2">
                    <Form.Group controlId="formBasicRange">
                    <ImSad className="float-left"/> {''} <span className="ml-3">{impactValue}%</span> <ImSmile className="float-right"/>
                    <Form.Control 
                        type="range"
                        value={getExplicitValue(impactValue)}
                        onChange={event => setImpactValue(getPercentValue(event.target.value))}                         
                        min={impactRange*-1} max={impactRange}
                        onKeyPress={event => handleFormKeyPress(event, "ImpactText")} />    
                   <p>
                    {isInvalidator && <GrCheckboxSelected onClick={event => setIsInvalidator(false)} onKeyPress={event => handleFormKeyPress(event, "ImpactText")} />}
                    {!isInvalidator && <GrCheckbox onClick={event => setIsInvalidator(true)} onKeyPress={event => handleFormKeyPress(event, "ImpactText")} />}
                    {" "}Invalidate Option
                    </p> 
                     
                    </Form.Group> 
                </div>
                </div>
        </td> 
        <td width="3%">
            <AiOutlineEnter onClick={event => handleClickEnter("ImpactText")}/>
        </td>       
        </tr>
        }

        {!isEditingImpact &&
        <tr className="" key={(props.impact!=undefined && props.impact.id!=undefined) ? props.impact.id : 'addNew'} > 
        <td className="">
            <div className="row">
            <div className = "col-md-6">
                {props.index}.{props.subIndex}.                 
                {impactValue!=undefined && " " } 
                {impactValue!=undefined && impactValue < 0 && impactValue > -25 && <ImSad/> }
                {impactValue!=undefined && impactValue < -25 && impactValue > -50 && <ImBaffled/> }
                {impactValue!=undefined && impactValue < -50 && impactValue > -75 && <ImFrustrated/> }
                {impactValue!=undefined && impactValue < -75 &&  <ImCrying/> }
                {impactValue!=undefined && impactValue == 0 && <ImNeutral/>  }
                {impactValue!=undefined && impactValue > 0 && impactValue < 25 && <ImCool/> }
                {impactValue!=undefined && impactValue > 25 && impactValue < 50 && <ImSmile/> }
                {impactValue!=undefined && impactValue > 50 && impactValue < 70 && <ImWink/>  }
                {impactValue!=undefined && impactValue > 75 && <ImHappy/>}
                {impactValue!=undefined && " " } 
                {props.impact.text}  
                <GrFormEdit onClick={() => {setIsEditingImpact(true);operationalController({type:'isEditingImpact', payload:true});}} /> 
            </div>
            <div className = "col-md-5 mt-2">
                <Form.Group controlId="formBasicRange">
                <ImSad className="float-left"/> {''} <span className="ml-3">{impactValue}%</span> <ImSmile className="float-right"/>
                <Form.Control 
                    type="range"
                    value={getExplicitValue(impactValue)}
                    onChange={event => hotSetImpactValue(event.target.value)}                         
                    min={impactRange*-1} max={impactRange} />  
                <p>
                    {isInvalidator && <GrCheckboxSelected onClick={event => hotSetIsInvalidator(false) } />}
                    {!isInvalidator && <GrCheckbox onClick={event => hotSetIsInvalidator(true) } />}
                    {" "}Invalidate Option
                </p>               
                </Form.Group> 
            </div>
            <div className = "col-md-1">
                {props.subIndex > 1 && <AiOutlineArrowUp className="float-right"  onClick={() => {handleImpactEvents("up","ImpactText")}} /> }
                {props.subIndex < props.option.impacts.length && <AiOutlineArrowDown className="float-right" onClick={() => {handleImpactEvents("down","ImpactText")}} /> } 
            </div>
            </div>
        </td>                      
        <td width="2%" style={sytles.removeButton} >    
            <GrFormTrash className="float-right"  onClick={() => {handleImpactEvents("remove","ImpactText")}}/> 
         </td>
        </tr>
        }       
    </>
    );
}

export default Impact;
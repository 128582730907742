import React, { useEffect, useReducer, useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { Button, Modal } from 'react-bootstrap';
import {OperationalContext} from './App';
import { DecisionContext } from './Decision';
import {
    WhatsappShareButton,WhatsappIcon
} from "react-share";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { BsFile, BsFiles } from 'react-icons/bs';

const SaveDecisionDialog = (props) => {

    const decision = props.decision;

    //Navigation control
    const history = useHistory();

    //Op context
    const {operationalState, operationalController} = useContext(OperationalContext);
    
    //Core hierarchy
    const {state, dispatch} = useContext(DecisionContext);

    const closeAndRedirect = () => {
        operationalController({type:'isSavingDecision', payload:false});
        history.push("/decision/" + decision.id);
    }

    return (

        <Modal
            show={(operationalState.isSavingDecision)}
            onHide={() => closeAndRedirect()}
        >

            <Modal.Header closeButton>
                <h4>Your decision has been saved! </h4>
            </Modal.Header>
            <Modal.Body>
                Use this URL to <b>edit</b> the decision: <br /><br />
                <ul>
                    <li>
                        <a href={"https://www.wikikratos.com/#/decision/" + state.id}>
                            {"/#/decision/" + state.id}
                        </a>
                    </li>
                </ul>
                Copy link {' '}
                <CopyToClipboard text={"https://www.wikikratos.com/#/decision/" + state.id} >
                    <BsFiles />
                </CopyToClipboard> {' '}
                <WhatsappShareButton title={"I want share with you my new decision: " + state.metadata.title + " | "} url={"https://www.wikikratos.com/#/decision/" + state.id}>
                    <WhatsappIcon size={22} round={true} />
                </WhatsappShareButton>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => closeAndRedirect()}> Close </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default SaveDecisionDialog;
import React, { useEffect, useReducer, useState, useContext } from 'react';
import { Button, Col, Form, Modal } from 'react-bootstrap';
import { GrFormEdit } from 'react-icons/gr';
import { AiOutlineEnter, AiOutlineFunction} from 'react-icons/ai';
import Option from './Option.js';
import {v4 as uuidv4} from 'uuid';
import useAPI from './useAPI';
import {FBAdd, FBGet,FBUpdate} from './useFireBase';
import {OperationalContext} from './App';
import axios from 'axios';
import Deliberation from './Deliberation.js';
import {replaceElement,reorderList} from './Utils';
import SaveDecisionDialog from './SaveDecisionDialog';
import { useHistory } from "react-router-dom";
import { DecisionContext } from './Decision';

import {  EditorState, convertToRaw, ContentState  } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';


function DecisionDetailDialog(props) {
    
    const {operationalState, operationalController} = useContext(OperationalContext);
    const {state, dispatch} = useContext(DecisionContext);

    const decision = state;

    const [decisionDescription, setDecisionText] = useState("");
    const [editorState, onEditorStateChange] = useState(
        (decision.metadata!=undefined && decision.metadata.detail!=undefined) 
        ? EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(decision.metadata.detail).contentBlocks))
        : EditorState.createEmpty()
    );
    const [isCostActive, setActiveCost] = useState(decision.metadata.isCostActive!=undefined ? decision.metadata.isCostActive : false);
    const [magnitude, setMagnitude] = useState(decision.metadata.magnitude!=undefined ? decision.metadata.magnitude : "€");

    const close = () => {
        operationalController({type:'isConfiguringDecision', payload:false});
    }

    const save = () => {
        const detail = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        const metadata = {...decision.metadata, "detail":detail, "magnitude":magnitude, "isCostActive":isCostActive};
        dispatch({type:'editDecision', payload:metadata});
        operationalController({type:'isConfiguringDecision', payload:false});
        //operationalController({type:'isConfiguringDecision', payload:false});
    }

    const styles = {
        editor:{
            border: "1px solid #F1F1F1 !important",
	        borderRadius: "2px !important"
        }
    }

    return (
        <Modal
            show={(operationalState.isConfiguringDecision)}
            onHide={() => close()}
            size="lg"
            centered={true}
        >

        <Modal.Header closeButton>            
            <h4>Configure your decision </h4>
        </Modal.Header>
        <Modal.Body>
        <div className="container">
            <div className="row">
                <div className="col">
                <Form.Group>
                    <p>Description</p>
                    <Editor
                        editorState={editorState}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={onEditorStateChange}
                    />
                </Form.Group>
                </div>
            </div>
            <div className="row">
                <div className="col">
                <Form.Group>
                    <Form.Check type="checkbox" checked={isCostActive} label="Add cost in options" onChange={e => setActiveCost(!isCostActive)} />
                    {isCostActive &&
                    <Col className="mt-2">
                         <Form.Label>Magnitude</Form.Label>
                         <Form.Control type="text" placeholder="€,M, Minute, Hours .." value={magnitude} onChange={e => setMagnitude(e.target.value)}  />
                    </Col>
                    }
                </Form.Group>
                </div>
            </div>
        </div>
            
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={() => save()} > Save </Button>
            <Button onClick={() => close()} > Close </Button>
        </Modal.Footer>
    </Modal>
    );
}


export default DecisionDetailDialog;
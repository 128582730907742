import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import  firebase from 'firebase/app';
import AppRouter from './Router';

var config = {
  apiKey: "AIzaSyD8baCpU_ITnDrk9cotFUdRhJAtysQjVTE",
  authDomain: "wiki-kratos.firebaseapp.com",
  databaseURL: "https://wiki-kratos-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "wiki-kratos",
  storageBucket: "wiki-kratos.appspot.com",
  messagingSenderId: "1069019769087",
  appId: "1:1069019769087:web:6e302c948490154d3304ca",
  measurementId: "G-S8VTQDMCPL",
  region : "europe-west1"
}

firebase.initializeApp(config);
ReactDOM.render(
  <React.StrictMode>
    <AppRouter />
  </React.StrictMode>,
  document.getElementById('root')
);


import React from 'react';

import {
    Switch,
    Route,
    HashRouter
  } from "react-router-dom"; 
import App from './App';

function AppRouter() {
  return(
    <HashRouter>
        <Switch>
            <Route key={"decision"} exact path="/decision/:decisionId" component={App} />
            <Route key={"home"}  path="/" component={App} />
        </Switch>
    </HashRouter>
  );
}

export default AppRouter;
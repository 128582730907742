import React, { useEffect, useReducer, useState, useContext } from 'react';
import { Button, Form, Table } from 'react-bootstrap';
import { GrConfigure, GrFormEdit } from 'react-icons/gr';
import { AiOutlineEnter, AiOutlineFunction} from 'react-icons/ai';
import Option from './Option.js';
import {v4 as uuidv4} from 'uuid';
import useAPI from './useAPI';
import {FBAdd, FBGet,FBUpdate} from './useFireBase';
import {OperationalContext} from './App';
import axios from 'axios';
import Deliberation from './Deliberation.js';
import {replaceElement,reorderList} from './Utils';
import SaveDecisionDialog from './SaveDecisionDialog';
import { useHistory } from "react-router-dom";
import DecisionDetailDialog from './DecisionDetailDialog.js';


export const DecisionContext = React.createContext();
export const decisionInitialState = {
    options:[], metadata : {}, config : {impactRange:3}
};


function Decision(props) {
    
    //json-server /Data/Projects/WKK/sourceCode/data-test/decision.json
    const allowSave = true;
    const history = useHistory();

    //Op context
    const {operationalState, operationalController} = useContext(OperationalContext);

    //Endpoint   
    //const saveDecion = useAPI(props.decisionId);
    const saveDecion = FBGet(props.decisionId);

    //Core Full Object
    const [state, dispatch] = useReducer(decisionReducer, decisionInitialState);
    
    //Decision Attributes
    const [decisionText, setDecisionText] = useState("");
    const [isEditingDecision, setIsEditingDecision] = useState(true);

    useEffect(()=>{
        if(props.decisionId != undefined){
            dispatch({type:'get', payload: saveDecion});
            //Load Decision Object
            setDecisionText(saveDecion.metadata.title);
            setIsEditingDecision(false);
        }       
    },[saveDecion]);

    const handleFormKeyPress = (event, item) => {
        if (event.key === "Enter") {
            event.preventDefault();
            if (item === "DecisionText") {
                setIsEditingDecision(false);
            }
        }
    }

    const handlePersistDecision = async () =>{
        if(state.id === undefined){           
            //Adding new decision
            const metadata = {...state.metadata,title:decisionText };
            const newDecision = {...state, id: uuidv4(), metadata};
            debugger;             
            dispatch({type:'addDecision', payload:newDecision});
            FBAdd(newDecision);
            operationalController({type:'isSavingDecision', payload:true})
            //await axios.post("http://localhost:3000/decisions",newDecision);
        }else{
            //Updating decision
            const metadata = {...state.metadata,title:decisionText };
            const updateDecision = {...state, metadata}  
            debugger;  
            dispatch({type:'editDecision', payload:{metadata:{title:decisionText}}});
            FBUpdate(updateDecision.id,updateDecision);
            //await axios.patch("http://localhost:3000/decisions"+"/"+updateDecision.id,updateDecision); 
        }        
    }

    const sytles ={
        optionsSeparator: {
            paddingTop: "3px",
            paddingBottom: "3px"
        }
    }

    return (
       <DecisionContext.Provider value={{state, dispatch}}>
       
       {operationalState.isDeliberationScreen && <Deliberation/>}
       {operationalState.isSavingDecision && <SaveDecisionDialog decision = {state} />}
       {operationalState.isConfiguringDecision && <DecisionDetailDialog/>}

       {!operationalState.isDeliberationScreen && 
       <Form id="DecisionEditor">           
            <div className="row mt-4">                
                {isEditingDecision &&
                <>
                    <div className="col-md-10 col-10">
                        <Form.Control
                            type="text"
                            value={decisionText}
                            placeholder="decision"
                            onChange={event => setDecisionText(event.target.value)}
                            onKeyPress={event => handleFormKeyPress(event, "DecisionText")}
                        />
                       
                    </div>
                    <div className="col-md-1 col-1">
                        {
                        decisionText != undefined 
                        && decisionText != "" 
                        && <GrConfigure onClick={() => {operationalController({type:'isConfiguringDecision', payload:true})}}  />}
                    </div>
                    <div className="col-md-1 col-1">                        
                        <AiOutlineEnter onClick={() => setIsEditingDecision(false)} />
                    </div>
                </>    
                }
                {!isEditingDecision &&
                <div className="col-md-12">
                    <h1>{decisionText} <GrFormEdit onClick={() => {setIsEditingDecision(true)}} /> </h1> 
                </div>    
                }
            </div>
            
            {!isEditingDecision && state.metadata.detail != undefined && <div className="row mt-1"> 
                <div className="col-md-12 col-12">
                    <div dangerouslySetInnerHTML={ {__html: state.metadata.detail}} />
                </div>
            </div> }
            
            <Table className="mt-4" key="table">
            <tbody key="tbody">
            {state.options.map((option, index) => (
                <React.Fragment key={option.id}>
                <Option key={option.id} option={option} className={index} index={index+1} ></Option>     
                <tr key={option.id+"-separator"}><td  key={option.id+"-td-separator"} colSpan={3} style={sytles.optionsSeparator}></td></tr>           
                </React.Fragment>
            ))}        
            {!isEditingDecision && decisionText != undefined && !operationalState.isEditingOption && 
                <Option key={'addKey'} option={undefined}></Option>
            }
            </tbody>
            </Table>
            
            <div className="row mt-3">
            <div className="col">
                {state.options.length > 1 && allowSave && <Button variant="primary" className="float-right" onClick={()=> {handlePersistDecision()}} >Save</Button>}
                {state.options.length > 1 && <Button variant="primary" onClick={() => operationalController({type:'isDeliberationScreen', payload:true})} >Deliberation</Button>}
            </div>  
            </div>    

        </Form>}
        </DecisionContext.Provider>
    );
}

export const decisionReducer = (state, action) => {
    
    switch(action.type){
        case 'get':
            return action.payload 

        case 'addDecision':           
            return action.payload     
        
        case 'editDecision': 
            return {...state, metadata:action.payload}

        case 'addOption':
            const newOption = {...action.payload, id: uuidv4(), impacts:[]}
            const addedOptions = [...state.options, newOption]
            return {...state, options:addedOptions}
        
        case 'editOption':
            const updatedOption = {...action.payload}
            return {...state, options:replaceElement(state.options, updatedOption)}
        
        case 'upOption':
            return {...state, options:reorderList(state.options,action.payload,'up')}

        case 'downOption':
            return {...state, options:reorderList(state.options,action.payload,'down')}    

        case 'removeOption':
            const filteredOptions = state.options.filter(option => option.id !== action.payload)
            return {...state, options:filteredOptions}
        
        case 'addImpact':
            const newImpact = {...action.payload.impact, id: uuidv4()}
            const option = state.options.find(option => option.id === action.payload.optionId);
            option.impacts = [...option.impacts, newImpact]            
            return {...state, options:replaceElement(state.options, option)}
        
        case 'editImpact':
            const updateImpact = {...action.payload.impact}            
            const option2 = state.options.find(option => option.id === action.payload.optionId);            
            option2.impacts = replaceElement(option2.impacts, updateImpact);           
            return {...state, options:replaceElement(state.options, option2)}   

        case 'removeImpact':
            const option3 = state.options.find(option => option.id === action.payload.optionId);
            option3.impacts = option3.impacts.filter(impact => impact.id !== action.payload.impactId)           
            return {...state, options:replaceElement(state.options, option3)} 
            
        default: 
            return  {}
    }
}

export default Decision;